import { Box, CircularProgress } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';

import { getPreAssessmentReportByRegion } from '../../redux/slice/organization-dashboard/organization-api';
import {
  OrganizationProps,
  PresassessmentGroupData,
} from '../../redux/slice/organization-dashboard/organization.types';
import { GenericDataTable } from '../common/GenericDataTable';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface FilterOptions {
  state: string;
  fromDate: string;
  toDate: string;
}

export const PreAssessmentReportRegion: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [filters, setFilters] = useState<FilterOptions>({
    state: 'All',
    fromDate: '',
    toDate: '',
  });
  const [swotdata, setSwotData] = useState<PresassessmentGroupData | null>(null);
  const [loading, setLoading] = useState(true);

  const [dateError, setDateError] = useState<string | null>(null);

  const fetchData = async () => {
    setLoading(true);

    try {
      const result =
        orgnizationId &&
        (await getPreAssessmentReportByRegion(orgnizationId, filters?.fromDate, filters?.toDate));
      if (result) {
        setSwotData(result);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Something went wrong');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  // Handle filter change
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };

      if (
        updatedFilters.fromDate &&
        updatedFilters.toDate &&
        updatedFilters.toDate < updatedFilters.fromDate
      ) {
        setDateError('⚠️ "To" date cannot be earlier than "From" date.');
      } else {
        setDateError(null);
      }

      return updatedFilters;
    });
  };

  // Convert groupedData into table format
  const tableData = swotdata?.completedCounts
    ? Object.keys(swotdata.completedCounts).map((state) => ({
        state,
        totalUsers: swotdata.completedCounts[state], // Now TypeScript knows this is valid
      }))
    : [];

  // Filter table data based on state
  const filteredTableData =
    filters.state === 'All'
      ? tableData
      : tableData && tableData.filter((item) => item.state === filters.state);

  // Prepare bar chart data
  const barChartData = {
    labels: filteredTableData && filteredTableData.map((item) => item.state),
    datasets: [
      {
        label: 'Pre-Employment Completed',
        data: filteredTableData && filteredTableData.map((item) => item.totalUsers),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'end',
      align: 'top',
      formatter: (value: string) => `${value}`,
      font: {
        size: 14,
        weight: 'bold',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'State',
          font: {
            size: 18,
            weight: 'bold',
          },
          padding: {
            top: 20,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Users',
          font: {
            size: 18,
            weight: 'bold',
          },
          padding: {
            bottom: 20,
          },
        },
        beginAtZero: true,
      },
    },
  } as const;

  return (
    <div className="space-y-8">
      <h2 className="text-3xl text-purple-600 text-center font-bold">
        Pre-Employment Report by Region
      </h2>
      <h3 className="text-xl font-semibold mb-5">
        Pre-Employment Report of {swotdata?.totalRecords || 0} users
      </h3>

      {/* Filters Section */}
      <div className="bg-white p-4 shadow-md rounded-lg">
        <div className="max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-4 gap-4">
          {/* State Filter */}
          <div>
            <label htmlFor="state" className="text-lg font-semibold block mb-1">
              State:
            </label>
            <select
              name="state"
              id="state"
              value={filters.state}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-md w-full"
            >
              <option value="All">All</option>
              {swotdata &&
                Object.keys(swotdata?.completedCounts)
                  .sort()
                  .map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
            </select>
          </div>

          {/* From Date Filter */}
          <div>
            <label htmlFor="fromDate" className="text-lg font-semibold block mb-1">
              From:
            </label>
            <input
              type="date"
              name="fromDate"
              id="fromDate"
              value={filters.fromDate}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-md w-full"
            />
          </div>

          {/* To Date Filter */}
          <div>
            <label htmlFor="toDate" className="text-lg font-semibold block mb-1">
              To:
            </label>
            <input
              type="date"
              name="toDate"
              id="toDate"
              value={filters.toDate}
              onChange={handleFilterChange}
              className={`px-4 py-2 border rounded-md w-full ${
                dateError ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {dateError && <p className="text-red-500 text-sm mt-1">{dateError}</p>}
          </div>

          {/* Apply Filter Button */}
          <div className="flex items-end">
            <button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={fetchData}
              className={`px-4 py-2 rounded-md w-full text-white ${
                dateError ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              }`}
              disabled={!!dateError}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>

      {/* Table & Bar Chart */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <div className="flex justify-between items-start space-x-8">
          <div className="flex-1">
            <GenericDataTable
              isLoading={false}
              columns={[
                { label: 'State', accessor: 'state' },
                { label: 'Total Users', accessor: 'totalUsers' },
              ]}
              data={filteredTableData && filteredTableData}
            />
          </div>
          <div className="flex-1 mt-10 max-h-[500px]">
            <div className="h-[400px] w-full relative">
              <Bar data={barChartData} options={barChartOptions} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
