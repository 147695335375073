import React, { useState } from 'react';

import { OrganizationProps } from '../../redux/slice/organization-dashboard/organization.types';

import { OrganizationStats } from './OrganizationStats';
import { PlatformUsageStats } from './PlatformUsageStats';
import { PreEmploymentStats } from './PreEmploymentStats';
import { UserCoursesStats } from './UserCoursesStats';

const tabs = [
  { label: 'Organization Stats', component: OrganizationStats },
  { label: 'Pre-Employment Stats', component: PreEmploymentStats },
  { label: 'Platform Usage Stats', component: PlatformUsageStats },
  { label: 'User Courses Stats', component: UserCoursesStats },
];

export const OrganizationUserOverview: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [activeTab, setActiveTab] = useState(0);

  const ActiveComponent = tabs[activeTab].component;

  return (
    <div className="w-full p-4">
      {/* Tabs Navigation */}
      <div className="flex space-x-4 border-b-2 mb-4">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`px-4 py-2 text-sm font-semibold text-gray-700 rounded-t-lg focus:outline-none ${
              activeTab === index ? 'bg-purple-600 text-white' : 'bg-gray-200'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Active Tab Content */}
      <div className="w-full overflow-hidden bg-gray-50 rounded-lg shadow-md p-4">
        <ActiveComponent orgnizationId={orgnizationId} />
      </div>
    </div>
  );
};
