/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  Auth,
  UserCredential,
  User,
  onAuthStateChanged,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';

import { loginAction, logoutAction } from '../../redux/slice/auth/auth.slice';
import { AddUserClaimDto, LoginDTO, SignupDTO, UserRole } from '../../redux/slice/auth/auth.types';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import store from '../../redux/store';
import { EnvironmentVariable } from '../../utils/constants';
import logger from '../logger';

import { getIsOtpVerified } from './mfa-auth';

/**
 * the currently authenticated user
 */
let currentUser: CustomUser | null;

/**
 * registers a new user's account
 *
 * @todo save user's first and last name
 */
export async function signup({ email, password }: SignupDTO): Promise<CustomUser> {
  const auth: Auth = getAuth();
  try {
    const result: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
    logger.debug('Successfully signed up.', result);
    const customUser: CustomUser = await getCustomUserObject(result.user);

    setCurrentUser(customUser);

    return customUser;
  } catch (e: unknown) {
    logger.error('Failed to signup.', e);
    throw e;
  }
}

/**
 * login to a user's account
 */
export async function login({ email, password }: LoginDTO): Promise<CustomUser> {
  const auth: Auth = getAuth();

  try {
    const result: UserCredential = await signInWithEmailAndPassword(auth, email, password);
    logger.debug('Successfully logged in.', result);

    const customUser: CustomUser = await getCustomUserObject(result.user);
    setCurrentUser(customUser);

    return customUser;
  } catch (e: unknown) {
    logger.error('Failed to login.', e);
    throw e;
  }
}

/**
 * log out of the currently authenticated user
 */
export async function logout(): Promise<void> {
  const auth: Auth = getAuth();

  try {
    await signOut(auth);
    setCurrentUser(null);
    logger.debug('Successfully logged out.');
  } catch (e: unknown) {
    logger.error('Failed to logout.', e);
    throw e;
  }
}

/**
 * return the currently authenticated user
 */
export async function getCurrentUser(): Promise<CustomUser | null> {
  if (currentUser) {
    return currentUser;
  }

  const auth: Auth = getAuth();
  if (auth.currentUser) {
    return await getCustomUserObject(auth.currentUser);
  }

  return null;
}

/**
 * listens to changes to authentication state
 */
export function registerAuthObserver(): void {
  const auth: Auth = getAuth();

  onAuthStateChanged(auth, (user: User | null) => {
    if (user) {
      logger.debug('Auth state change: user logged in.', user);
      auth.currentUser
        ?.getIdTokenResult()
        .then((result) => {
          const customClaims = result.claims;
          const customUser: CustomUser = {
            user: user,
            claims: {
              isAsaUser: (customClaims['isAsaUser'] || false) as boolean,
              asaUserId: (customClaims['asaUserId'] || 0) as number,
              role: result?.claims.role
                ? (result?.claims.role as UserRole)
                : ((customClaims['isAsaUser'] || false) as boolean)
                ? UserRole.ASA_USER
                : UserRole.STUDENT,
              organizationId: result?.claims.organizationId
                ? (result?.claims.organizationId as string)
                : null,
            },
          };
          if (getIsOtpVerified()) {
            store.dispatch(loginAction(customUser));
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      logger.debug('Auth state change: user not authenticated.', user);
      store.dispatch(logoutAction());
    }
  });
}

/**
 * sets the currently authenticated user
 */
export function setCurrentUser(user: CustomUser | null): void {
  currentUser = user;
}

async function getCustomUserObject(user: User, forceRefresh: boolean = false): Promise<CustomUser> {
  const idTokenResult = await user.getIdTokenResult(forceRefresh);
  const customClaims = idTokenResult?.claims;
  const customUser: CustomUser = {
    user: user,
    claims: {
      isAsaUser: (customClaims['isAsaUser'] || false) as boolean,
      asaUserId: (customClaims['asaUserId'] || 0) as number,
      role: idTokenResult?.claims.role
        ? (idTokenResult?.claims.role as UserRole)
        : ((customClaims['isAsaUser'] || false) as boolean)
        ? UserRole.ASA_USER
        : UserRole.STUDENT,
      organizationId: idTokenResult?.claims.organizationId
        ? (idTokenResult?.claims.organizationId as string)
        : null,
    },
  };
  return customUser;
}

export const AddUserClaims = async (
  dto: AddUserClaimDto,
  updateClaimsUrl: string | undefined,
): Promise<Response> => {
  if (!updateClaimsUrl) throw new Error('Update claims URL is not defined.');

  const response = await fetch(`${updateClaimsUrl}?userId=${encodeURIComponent(dto.id)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      customAttributes: { isAsaUser: dto.isAsaUser, asaUserId: dto.asaUserId },
    }),
  });

  if (response.ok) {
    const auth: Auth = getAuth();
    if (auth.currentUser) {
      const customUser: CustomUser = await getCustomUserObject(auth.currentUser, true);
      setCurrentUser(customUser);
    }
  }
  return response;
};

export const DeleteUser = async (
  userId: string,
  deleteUserUrl: string | undefined,
): Promise<Response> => {
  if (deleteUserUrl) {
    const response = await fetch(`${deleteUserUrl}?userId=${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  }
  throw new Error('Delete user URL is not defined.');
};

// function reset new password with current password
export const UpdateUserPassword = async (currentPassword: string, newPassword: string) => {
  const auth: Auth = getAuth();
  const user = auth.currentUser;

  if (user && user.email) {
    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential); // check the old-password

      await updatePassword(user, newPassword); // Update the password
      return { status: true, message: 'Password updated successfully', email: user.email };
    } catch (error) {
      return { status: false, message: 'Please check your credentials', email: user.email };
    }
  } else {
    return { status: false, message: 'No user is logged in' };
  }
};

// function send password reset link for forgot password
export const sendPasswordResetLink = async (email: string) => {
  const auth: Auth = getAuth();

  await sendPasswordResetEmail(auth, email);
  if (auth) {
    return true;
  } else {
    return false;
  }
};

// function to reset forgoten password
export const changePassword = async (oobCode: string, newPassword: string) => {
  const auth: Auth = getAuth();
  await confirmPasswordReset(auth, oobCode, newPassword);
  if (auth) {
    return true;
  } else {
    return false;
  }
};

export const checkResetLinkStatus = async (oobCode: string) => {
  const auth = getAuth();
  try {
    const email = await verifyPasswordResetCode(auth, oobCode);
    return { valid: true, email };
  } catch (error: any) {
    return {
      valid: false,
      error:
        'The password reset link was invalid, possibly because it has already been used. Please request a new password reset.',
    };
  }
};

export const GetOrganizationActiveSubscription = async (
  organizationId: string,
): Promise<Response> => {
  const requestUrl = process.env[EnvironmentVariable.REACT_APP_GET_ORG_SUBSCRIPTION_URL];
  const response = await fetch(`${requestUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      organizationId: organizationId,
    }),
  });

  return response;
};

export const UpdateRegistrationCount = async (
  organizationId: string,
  subscriptionId: string,
): Promise<Response> => {
  const requestUrl =
    process.env[EnvironmentVariable.REACT_APP_UPDATE_ORG_SUBSCRIPTION_REGISTRATION_COUNT_URL];

  const response = await fetch(`${requestUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      organizationId: organizationId,
      subscriptionId: subscriptionId,
    }),
  });

  return response;
};
const oidcId = process.env[EnvironmentVariable.REACT_APP_OIDC_PROVIDER_ID];
const provider = oidcId && new OAuthProvider(oidcId); // Replace <provider-id> with your configured OIDC provider ID in Firebase
provider && provider.addScope('openid'); // Add necessary scopes
provider && provider.addScope('email');
provider && provider.addScope('profile');
// Start the sign-in process

export const signInWithRedirectUrl = async () => {
  const auth: Auth = getAuth();

  try {
    // Start the redirect sign-in process
    if (provider) {
      const signInResult = await signInWithPopup(auth, provider);

      const customUser: CustomUser = await getCustomUserObject(signInResult.user);
      if (customUser) {
        // User is successfully authenticated
        const user = customUser.user; // The authenticated user
        if (!user) {
          throw new Error('Sign-up process failed: No user returned.');
        }
        setCurrentUser(customUser);
        return customUser;
      }
    }
    return;
  } catch (error) {
    logger.error('Failed to login.', error);
    throw error;
  }
};
