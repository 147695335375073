import { Box, CircularProgress } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';

import { getOrganizaationAllUserSwotReport } from '../../redux/slice/organization-dashboard/organization-api';
import {
  OrganizationProps,
  SwotReport,
} from '../../redux/slice/organization-dashboard/organization.types';
import { GenericDataTable } from '../common/GenericDataTable';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
interface FilterOptions {
  fromDate: string;
  toDate: string;
}

export const AllUsersSwotReport: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [filters, setFilters] = useState<FilterOptions>({
    fromDate: '',
    toDate: '',
  });
  const [swotdata, setSwotData] = useState<SwotReport | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };

      // Validate dates
      if (
        updatedFilters.fromDate &&
        updatedFilters.toDate &&
        updatedFilters.toDate < updatedFilters.fromDate
      ) {
        setDateError('⚠️ "To" date cannot be earlier than "From" date.');
      } else {
        setDateError(null);
      }

      return updatedFilters;
    });
  };
  const fetchData = async () => {
    setLoading(true);

    try {
      const result =
        orgnizationId &&
        (await getOrganizaationAllUserSwotReport(
          orgnizationId,
          filters?.fromDate,
          filters?.toDate,
        ));
      if (result) {
        setSwotData(result);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Something went wrong');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    void fetchData();
  }, []);

  const getTop3Skills = (data: Record<string, number>) => {
    return Object.entries(data)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3);
  };

  return (
    <div className="space-y-8">
      <h2 className="text-3xl text-purple-600 text-center font-bold">
        All Users SWOT Analysis Report{' '}
      </h2>
      <div className="bg-white p-4 shadow-md rounded-lg">
        <div className="max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-4 gap-4">
          {/* From Date Filter */}
          <div>
            <label htmlFor="fromDate" className="text-lg font-semibold block mb-1">
              From:
            </label>
            <input
              type="date"
              name="fromDate"
              id="fromDate"
              value={filters.fromDate}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-md w-full"
            />
          </div>

          {/* To Date Filter */}
          <div>
            <label htmlFor="toDate" className="text-lg font-semibold block mb-1">
              To:
            </label>
            <input
              type="date"
              name="toDate"
              id="toDate"
              value={filters.toDate}
              onChange={handleFilterChange}
              className={`px-4 py-2 border rounded-md w-full ${
                dateError ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {dateError && <p className="text-red-500 text-sm mt-1">{dateError}</p>}
          </div>

          {/* Apply Filter Button */}
          <div className="flex items-end">
            <button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={fetchData}
              className={`px-4 py-2 rounded-md w-full text-white ${
                dateError ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              }`}
              disabled={!!dateError}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        swotdata?.swotData &&
        Object.entries(swotdata.swotData).map(([skillCategory, counts]) => {
          // Get top 3 skills and calculate their percentages
          const topSkills = getTop3Skills(counts);
          const labels = topSkills.map(([category]) => category); // X-axis: Skill names
          const data = topSkills.map(([, count]) =>
            ((count / swotdata.totalUsers) * 100).toFixed(2),
          );

          const getBackgroundColor = (category: string) => {
            switch (category.toLowerCase()) {
              case 'strengths':
                return 'rgba(34, 197, 94, 0.5)'; // Green
              case 'weaknesses':
                return 'rgba(249, 115, 22, 0.5)'; // Orange
              case 'opportunities':
                return 'rgba(234, 179, 8, 0.5)'; // Yellow
              case 'threats':
                return 'rgba(239, 68, 68, 0.5)'; // Red
              default:
                return 'rgba(124, 58, 237, 0.5)'; // Default Purple
            }
          };

          const getBorderColor = (category: string) => {
            switch (category.toLowerCase()) {
              case 'strengths':
                return 'rgba(34, 197, 94, 1)'; // Green
              case 'weaknesses':
                return 'rgba(249, 115, 22, 1)'; // Orange
              case 'opportunities':
                return 'rgba(234, 179, 8, 1)'; // Yellow
              case 'threats':
                return 'rgba(239, 68, 68, 1)'; // Red
              default:
                return 'rgba(75, 192, 192, 1)'; // Default Teal
            }
          };

          const chartData = {
            labels: labels,
            datasets: [
              {
                label: `${skillCategory} Top 3 Skills (%)`,
                data: data,
                backgroundColor: getBackgroundColor(skillCategory),
                borderColor: getBorderColor(skillCategory),
                borderWidth: 1,
              },
            ],
          };

          // Prepare table data for display

          const tableData = Object.entries(counts as Record<string, number>).map(
            ([category, count]) => {
              const percentage = ((count / swotdata.totalUsers) * 100).toFixed(2);
              return {
                category,
                count,
                totalUsers: swotdata.totalUsers,
                percentage,
              };
            },
          );

          const columns = [
            { label: 'Category', accessor: 'category' },
            { label: 'Count', accessor: 'count' },
            { label: 'Total Users', accessor: 'totalUsers' },
            { label: 'Percentage (%)', accessor: 'percentage' },
          ];

          const barChartOptions = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: true,
              },
            },
            datalabels: {
              display: true,
              color: 'black',
              anchor: 'end',
              align: 'top',
              formatter: (value: string) => `${value}%`,
              font: {
                size: 14,
                weight: 'bold',
              },
            },

            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Skills',
                  font: {
                    size: 18,
                    weight: 'bold',
                  },
                  padding: {
                    top: 20,
                  },
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Percentage (%)',
                  font: {
                    size: 18,
                    weight: 'bold',
                  },
                  padding: {
                    bottom: 20,
                  },
                },
                beginAtZero: true,
              },
            },
          } as const;

          return (
            <div key={skillCategory} className="mb-8">
              <h3 className="text-2xl text-center  font-semibold mt-10 mb-10 ">{skillCategory}</h3>

              <div className="flex justify-between items-start space-x-8">
                {/* Table */}
                <div className="flex-1">
                  <GenericDataTable isLoading={false} columns={columns} data={tableData} />
                </div>

                {/* Chart */}
                <div className="flex-1 mt-10 max-h-[400px]">
                  <div className="h-[300px] relative">
                    <Bar data={chartData} options={barChartOptions} />
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
