export type UserEngagementData = {
  name?: string;
  email?: string;
  timeSpentOnPlatform?: number;
  numberOfTimeLoggedIn?: number;
  lastLoggedInDate?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Catch all other fields
};
export type OrganizationProps = {
  orgnizationId: string | null | undefined;
};

export type UserStats = {
  totalUsersCount: number;
  completedPreEmploymentCount: number;
  pending: number;
  avgTimeSpentOnPlatform?: number;
};

export type PreEmploymentUserData = {
  userId: string;
  name: string;
  email: string;
  intake?: number;
  employmentReadiness?: number;
  skills?: number;
  careerReadiness?: number;
  expirence?: number;
};

export type Course = {
  id: string;
  courseName: string;
  progress: number;
  preAssessmentScore: number;
  postAssessmentScore: number;
};

export type UserCourseData = {
  userId: string;
  name: string;
  email: string;
  courses: Course[];
  AdaptingToChange: number; // add fields for each course directly
  EffectiveCommunicationWithDifferentCommunicationStyles: number;
  EmotionalIntelligenceInTheWorkplace: number;
  JumpstartCommunicator: number;
  PerceptionAndUnderstandingPreferences: number;
  WorkplaceCommunicationBasics: number;
  progress: number;
};

export const coursesColumns = [
  { id: 'name', label: 'Name', accessor: 'name' },
  { id: 'email', label: 'Email', accessor: 'email' },
  {
    id: 'module1PreAssessmentScore',
    label: 'Pre-assessment score',
    accessor: 'module1PreScore',
  },
  {
    id: 'JumpstartCommunicator',
    label: 'Jumpstart Communicator',
    accessor: 'JumpstartCommunicator',
  },
  {
    id: 'WorkplaceCommunicationBasics',
    label: 'Workplace Communication Basics',
    accessor: 'WorkplaceCommunicationBasics',
  },
  {
    id: 'EffectiveCommunicationWithDifferentCommunicationStyles',
    label: 'Effective Communication With Different Communication Styles',
    accessor: 'EffectiveCommunicationWithDifferentCommunicationStyles',
  },
  {
    id: 'module1PostAssessmentScore',
    label: 'Post-assessment score',
    accessor: 'module1PostScore',
  },
  {
    id: 'module2PreAssessmentScore',
    label: 'Pre-assessment score',
    accessor: 'module2PreScore',
  },
  {
    id: 'Perception&UnderstandingPreferences',
    label: 'Perception & Understanding Preferences',
    accessor: 'Perception&UnderstandingPreferences',
  },
  {
    id: 'EmotionalIntelligenceInTheWorkplace',
    label: 'Emotional Intelligence in the Workplace',
    accessor: 'EmotionalIntelligenceInTheWorkplace',
  },
  { id: 'AdaptingToChange', label: 'Adapting to Change', accessor: 'Adapting To Change' },
  {
    id: 'module2PostAssessmentScore',
    label: 'Post-assessment score',
    accessor: 'module2PostScore',
  },
  { label: 'Registration Date', accessor: 'createdAt' },
];

export type SubscriptionData = {
  subscriptionId: string;
  numberOfUsers: number;
  createdAt: string;
  endDate: string;
  users: SubscriptionUsersData[];
  status: string;
  amount: number;
  isPaymentProccessing: boolean;
  transactionId: string;
};

export type SubscriptionUsersData = {
  name: string;
  email: string;
  endDate?: string;
};

export interface SwotData {
  Strengths: Record<string, number>;
  Weaknesses: Record<string, number>;
  Opportunities: Record<string, number>;
  Threats: Record<string, number>;
}

export interface SwotReport {
  totalUsers: number;
  swotData: SwotData;
}

export type SWOTCategory = 'Strengths' | 'Weaknesses' | 'Threats' | 'Opportunities';

export interface GroupedData {
  [state: string]: {
    [ageRange: string]: SwotData;
  };
}

export interface PresassessmentGroupData {
  completedCounts: { [state: string]: number };
  totalRecords: number;
}
