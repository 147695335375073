import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/order
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

import { toast } from 'react-toastify';

import { getSwotReportAsPerRegionAndAge } from '../../redux/slice/organization-dashboard/organization-api';
import {
  GroupedData,
  OrganizationProps,
  SWOTCategory,
} from '../../redux/slice/organization-dashboard/organization.types';
import { GenericDataTable } from '../common/GenericDataTable';

// eslint-disable-next-line import/order
import { Box, CircularProgress } from '@mui/material';

ChartJS.register(CategoryScale, BarElement, Title, Tooltip, Legend);

interface FlattenedData {
  state: string;
  ageRange: string;
  category: string;
  skill: string;
  value: number;
}

interface FilterOptions {
  state: string;
  ageRange: string;
  fromDate: string;
  toDate: string;
}

interface Props {
  groupedData: GroupedData;
  totalRecords: number;
}

export const SWOTReportRegionAndAgeRange: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [filteredData, setFilteredData] = useState<FlattenedData[]>([]);
  const [filters, setFilters] = useState<FilterOptions>({
    state: 'All',
    ageRange: 'All',
    fromDate: '',
    toDate: '',
  });
  const [swotdata, setSwotData] = useState<Props | null>(null);

  const [loading, setLoading] = useState(true);

  const [dateError, setDateError] = useState<string | null>(null);

  const fetchData = async () => {
    setLoading(true);

    try {
      const result =
        orgnizationId &&
        (await getSwotReportAsPerRegionAndAge(orgnizationId, filters?.fromDate, filters?.toDate));
      if (result) {
        setSwotData(result);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Something went wrong');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    void fetchData();
  }, []);
  // Handle filter change
  const handleFilterChanges = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };

      // Validate dates
      if (
        updatedFilters.fromDate &&
        updatedFilters.toDate &&
        updatedFilters.toDate < updatedFilters.fromDate
      ) {
        setDateError('⚠️ "To" date cannot be earlier than "From" date.');
      } else {
        setDateError(null);
      }

      return updatedFilters;
    });
  };

  useEffect(() => {
    const flattenData = () => {
      const flattened: FlattenedData[] = [];

      Object.keys(swotdata?.groupedData || {}).forEach((state) => {
        Object.keys(swotdata?.groupedData[state] || {}).forEach((ageRange) => {
          const swotData = swotdata?.groupedData[state][ageRange]; // Now correctly accessing groupedData

          if (swotData) {
            (['Strengths', 'Weaknesses'] as SWOTCategory[]).forEach((category) => {
              Object.keys(swotData[category] || {}).forEach((skill) => {
                flattened.push({
                  state,
                  ageRange,
                  category,
                  skill,
                  value: swotData[category][skill],
                });
              });
            });
          }
        });
      });

      return flattened;
    };

    const flattenedData = flattenData();

    // Filter data based on selected filters
    const filtered = flattenedData.filter((item) => {
      return (
        (filters.state === 'All' || item.state === filters.state) &&
        (filters.ageRange === 'All' || item.ageRange === filters.ageRange)
      );
    });

    setFilteredData(filtered);
  }, [swotdata, filters]);

  // Handle filter changes
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const aggregatedData = filteredData.reduce((acc, { category, skill, value }) => {
    if (!acc[skill]) acc[skill] = { Strengths: 0, Weaknesses: 0 };

    // Aggregate strengths and weaknesses
    if (category === 'Strengths') {
      acc[skill].Strengths += value;
    } else if (category === 'Weaknesses') {
      acc[skill].Weaknesses += value;
    }

    return acc;
  }, {} as Record<string, { Strengths: number; Weaknesses: number }>);

  const tableData = Object.keys(aggregatedData).map((skill) => {
    const strengths = aggregatedData[skill].Strengths;
    const weaknesses = aggregatedData[skill].Weaknesses;
    const stateData = filteredData.filter((item) => item.skill === skill);

    return {
      skill,
      strengths,
      weaknesses,
      stateData,
    };
  });

  const strengthsChartData = {
    labels: Object.keys(aggregatedData),
    datasets: [
      {
        label: 'Strengths',
        data: Object.keys(aggregatedData).map((skill) => aggregatedData[skill].Strengths),
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
        borderColor: 'rgba(34, 197, 94, 1)',
        borderWidth: 1,
      },
    ],
  };

  const weaknessesChartData = {
    labels: Object.keys(aggregatedData),
    datasets: [
      {
        label: 'Weaknesses',
        data: Object.keys(aggregatedData).map((skill) => aggregatedData[skill].Weaknesses),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(249, 115, 22, 1)',
        borderWidth: 1,
      },
    ],
  };
  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'end',
      align: 'top',
      formatter: (value: string) => `${value}`,
      font: {
        size: 14,
        weight: 'bold',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Skills',
          font: {
            size: 18,
            weight: 'bold',
          },
          padding: {
            top: 20,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
          font: {
            size: 18,
            weight: 'bold',
          },
          padding: {
            bottom: 20,
          },
        },
        beginAtZero: true,
      },
    },
  } as const;

  return (
    <div className="space-y-8">
      <h2 className="text-3xl text-purple-600 text-center font-bold">SWOT Report</h2>
      <h3 className="text-xl font-semibold mb-5">
        SWOT Report of {swotdata?.totalRecords || 0} users
      </h3>

      {/* Filter options */}
      <div className="bg-white p-4 shadow-md rounded-lg">
        <div className="max-w-4xl mx-auto flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-8">
          <div className="flex flex-col items-center sm:items-start">
            <label htmlFor="state" className="text-lg font-semibold mb-2">
              State:
            </label>
            <select
              name="state"
              id="state"
              value={filters.state}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="All">All</option>
              {swotdata &&
                Object.keys(swotdata?.groupedData)
                  .sort()
                  .map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
            </select>
          </div>

          <div className="flex flex-col items-center sm:items-start">
            <label htmlFor="ageRange" className="text-lg font-semibold mb-2">
              Age Range:
            </label>
            <select
              name="ageRange"
              id="ageRange"
              value={filters.ageRange}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="All">All</option>
              {swotdata &&
                Array.from(
                  new Set(
                    Object.values(swotdata?.groupedData).flatMap((states) => Object.keys(states)),
                  ),
                )
                  .sort((a, b) => {
                    const lowerA = parseInt(a.split('-')[0]);
                    const lowerB = parseInt(b.split('-')[0]);

                    return lowerA - lowerB;
                  })
                  .map((ageRange) => (
                    <option key={ageRange} value={ageRange}>
                      {ageRange}
                    </option>
                  ))}
            </select>
          </div>
          {/* From Date Filter */}
          <div>
            <label htmlFor="fromDate" className="text-lg font-semibold block mb-1">
              From:
            </label>
            <input
              type="date"
              name="fromDate"
              id="fromDate"
              value={filters.fromDate}
              onChange={handleFilterChanges}
              min="2024-01-01"
              className="px-4 py-2 border border-gray-300 rounded-md w-full"
            />
          </div>

          {/* To Date Filter */}
          <div>
            <label htmlFor="toDate" className="text-lg font-semibold block mb-1">
              To:
            </label>
            <input
              type="date"
              name="toDate"
              id="toDate"
              value={filters.toDate}
              onChange={handleFilterChanges}
              min="2024-01-01"
              className={`px-4 py-2 border rounded-md w-full ${
                dateError ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {dateError && <p className="text-red-500 text-sm mt-1">{dateError}</p>}
          </div>

          {/* Apply Filter Button */}
          <div className="flex items-end">
            <button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={fetchData}
              className={`px-4 py-2 rounded-md w-full text-white ${
                dateError ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              }`}
              disabled={!!dateError}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <div>
          <div className="mb-8">
            {/* Strengths Table and Bar Chart */}
            <h3 className="text-xl font-semibold mb-4">Strengths</h3>

            <div className="flex justify-between items-start space-x-8">
              <div className="flex-1">
                <GenericDataTable
                  isLoading={false}
                  columns={[
                    { label: 'Skills', accessor: 'skill' },
                    { label: 'Count', accessor: 'strengths' },
                  ]}
                  data={tableData}
                />
              </div>
              <div className="flex-1 mt-10 max-h-[500px]">
                <div className="h-[400px] w-full relative">
                  {' '}
                  <Bar data={strengthsChartData} options={barChartOptions} />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            {/* Strengths Table and Bar Chart */}
            <h3 className="text-xl font-semibold mb-4">Weaknesses</h3>

            <div className="flex justify-between items-start space-x-8">
              <div className="flex-1">
                <GenericDataTable
                  isLoading={false}
                  columns={[
                    { label: 'Skills', accessor: 'skill' },
                    { label: 'Count', accessor: 'weaknesses' },
                  ]}
                  data={tableData}
                />
              </div>
              <div className="flex-1 mt-10 max-h-[500px]">
                <div className="h-[400px] w-full relative">
                  {' '}
                  <Bar data={weaknessesChartData} options={barChartOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
