import { Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  dispatchReminderEmail,
  getPreEmploymentData,
} from '../../redux/slice/organization-dashboard/organization-api';
import {
  OrganizationProps,
  PreEmploymentUserData,
} from '../../redux/slice/organization-dashboard/organization.types';
import logger from '../../services/logger';
import { GenericDataTable } from '../common/GenericDataTable';

export const PreEmploymentStats: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [filters, setFilters] = useState({
    name: '',
    email: '',
  });
  const [emailLoader, setEmailLoader] = useState(false);

  const [data, setData] = useState<PreEmploymentUserData[]>([]);
  const [loading, setLoading] = useState(true);

  const limit = 100; // Adjust as needed

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = orgnizationId && (await getPreEmploymentData(orgnizationId, limit));
        if (result) {
          setData(result?.employeeData ?? []);
        }
      } catch (error) {
        console.error('Error fetching pre-employment data:', { error });
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [orgnizationId, limit]);

  const handleFilterChange = (newFilters: Record<string, string>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const filteredData = data.filter((user) => {
    return (
      (!filters.name || user?.name?.toLowerCase().includes(filters.name)) &&
      (!filters.email || user?.email?.toLowerCase().includes(filters.email))
    );
  });

  const handleButtonClick = async (user: { name: string; email: string }) => {
    try {
      setEmailLoader(true);
      if (user) {
        const sendEmail = await dispatchReminderEmail(user);
        if (sendEmail?.status == 200) {
          toast.success(`Email sent to ${user.email}`);
        }
      }
    } catch (error) {
      toast.error('Something went wrong...');

      logger.error('error:', error);
    } finally {
      setEmailLoader(false);
    }
  };

  const preEmploymentColumns = [
    { label: 'Name', accessor: 'name' },
    { label: 'Email', accessor: 'email' },
    { label: 'Intake', accessor: 'intake' },
    { label: 'Employment Readiness', accessor: 'employmentReadiness' },
    { label: 'Skills', accessor: 'skills' },
    { label: 'Career Readiness', accessor: 'careerReadiness' },
    { label: 'Experience', accessor: 'expirence' },
    {
      label: 'Send Email', // Add button column
      accessor: 'action',
      Cell: (row: { name: string; email: string }) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => handleButtonClick(row)} // Button click event
        >
          Reminder
        </Button>
      ),
    },
    { label: 'Registration Date', accessor: 'createdAt' },
  ];
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="bold"
        className="border-purple-900 border-b-2 rounded-lg  mb-4 mt-4 text-purple-700 "
        gutterBottom
      >
        Pre-Employment Data (% completed)
      </Typography>
      <GenericDataTable
        isLoading={loading}
        columns={preEmploymentColumns}
        data={filteredData}
        filterFields={[
          { name: 'name', label: 'Filter by Name' },
          { name: 'email', label: 'Filter by Email' },
        ]}
        onFilterChange={handleFilterChange}
        ctaLoader={emailLoader}
      />
    </>
  );
};
