import { useState } from 'react';

import { OrganizationProps } from '../../redux/slice/organization-dashboard/organization.types';

import { AllUsersSwotReport } from './AllUsersSwotReport';
import { PreAssessmentReportRegion } from './Pre-assessment-report';
import { SWOTReportRegionAndAgeRange } from './SwotReport-region-age-range';

const Tab = ({
  isActive,
  onClick,
  children,
}: {
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <button
      className={`px-4 py-2 text-sm font-semibold text-gray-700 rounded-t-lg focus:outline-none ${
        isActive ? 'bg-purple-600 text-white' : 'bg-gray-200'
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const OrganizationReports: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [activeTab, setActiveTab] = useState<'report' | 'regionAgeRange' | 'preEmploymentReport'>(
    'report',
  );
  return (
    <div className="w-full p-4">
      <div className="mt-6 w-full overflow-hidden bg-gray-50  shadow-md p-4">
        {/* Tabs */}
        <div className="flex space-x-4 border-b-2 border-gray-300">
          <Tab isActive={activeTab === 'report'} onClick={() => setActiveTab('report')}>
            All Users SWOT Report
          </Tab>
          <Tab
            isActive={activeTab === 'regionAgeRange'}
            onClick={() => setActiveTab('regionAgeRange')}
          >
            SWOT Report by Region & Age Range
          </Tab>
          <Tab
            isActive={activeTab === 'preEmploymentReport'}
            onClick={() => setActiveTab('preEmploymentReport')}
          >
            Pre-Employment Report by region
          </Tab>
        </div>

        {/* Tab Content */}
        <div className="mt-4 ">
          {activeTab === 'report' && <AllUsersSwotReport orgnizationId={orgnizationId} />}
          {activeTab === 'regionAgeRange' && (
            <SWOTReportRegionAndAgeRange orgnizationId={orgnizationId} />
          )}
          {activeTab === 'preEmploymentReport' && (
            <PreAssessmentReportRegion orgnizationId={orgnizationId} />
          )}
        </div>
      </div>
    </div>
  );
};
